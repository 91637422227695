/* Target the container with horizontal scrollbar */
.column-container {
  overflow-x: scroll;
  display: flex;
  gap: 12px;
  overflow-x: scroll;
}

/* WebKit-based browsers (Chrome, Safari) */
.column-container::-webkit-scrollbar {
  width: 12px;
}

.column-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.column-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.column-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
