:root {
  --ck-border-radius: 0.375rem !important;
  --ck-color-base-active-focus: #2c5282 !important;
  --ck-use-border-color: #cbd5e0;
}

.ck-content {
  border: 1px solid var(--ck-use-border-color) !important;
  min-height: 200px;
}

.ck-toolbar {
  border-top: 1px solid var(--ck-use-border-color) !important;
  border-left: 1px solid var(--ck-use-border-color) !important;
  border-right: 1px solid var(--ck-use-border-color) !important;
}

.ck-content:focus {
  border-color: var(--ck-color-base-active-focus) !important;
  outline: 1px solid var(--ck-color-base-active-focus) !important;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  font-size: 12px;
}

.ck.ck-balloon-panel {
  z-index: 9999 !important;
}

.ck.ck-list {
  width: 280px;
}
.custom-item,
.custom-item-username {
  display: block;
}
.custom-item {
  font-weight: 600 !important;
  line-height: 1em !important;
}

.ck.ck-list__item .ck-button.ck-on {
  background-color: #f0f0f0 !important;
}
