.swiper-button-next,
.swiper-button-prev {
  position: fixed;
  top: calc(100vh / 2);
  margin-top: -100px;
}

.react-pdf__Page__textContent.textLayer {
  margin: auto !important;
}
.react-pdf__Page__annotations.annotationLayer {
  margin: auto !important;
  inset: 0;
}
