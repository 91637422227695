.routeLink {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in;
  border-left: 4px solid transparent;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.routeLink:hover {
  color: #333984 !important;
  background-color: #e2e8f0;
}

.active {
  border-color: #008cc9;
  background-color: #e2e8f0;
  font-weight: 600;
}

.profileFlex {
  display: block;
}

@media screen and (min-width: 662px) {
  .profileFlex {
    display: flex;
  }
}
